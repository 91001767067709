import './distributiva_espectaculos_fl.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';
import { Freya } from '../src/components/Freya/Freya';
import H4 from '../src/components/Titles/TitleH4single/TitleH4single';
import Heimdall from '../src/components/Heimdall/Heimdall';
import NeoAesir from '../src/components/NeoAesir/NeoAesir';
import Layout from '../src/components/Layout/Layout';
import SEO from '../src/helpers/seo';
import Hod from '../src/components/Hod/Hod';
import tt from '../src/helpers/translation';
import BlueArrow from '../src/components/BlueArrow/BlueArrow';
import Buttons from '../src/components/Buttons/Buttons';
import Bragi from '../src/components/Bragi/bragi';
import AnounceCovid from '../src/components/AnounceCovid/AnounceCovid';

export const GatsbyQuery = ({ data, pathContext: { locale } }) => graphql`
  query dist_shows_fl($locale: String!) {
    allRidesTitleBlock(filter: { park: { eq: "FerrariShows" }, lang: { eq: $locale } }) {
      edges {
        node {
          alt
          image
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    allRidesTitle(filter: { park: { eq: "FerrariShows" }, lang: { eq: $locale } }) {
      edges {
        node {
          name
          description
          text
        }
      }
    }
    allRidesSeoData(filter: { tag: { eq: "shows_fl" }, lang: { eq: $locale } }) {
      edges {
        node {
          _0 {
            title
          }
          _1 {
            description
          }
        }
      }
    }
    allShowsVideo(filter: { park: { eq: "FerrariShows" }, lang: { eq: $locale } }) {
      edges {
        node {
          video
        }
      }
    }
    allRidesSubBlock(
      filter: { park: { eq: "FerrariShows" }, lang: { eq: $locale } }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          localImage {
            childImageSharp {
              fluid(maxWidth: 320, maxHeight: 160, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
          imgsize
          title
          description
          summary
          disabled
          seeMore
          located
          features
          possibleFilters1
          possibleFilters2
          buttons {
            cta
            size
            color
            ctaText
          }
        }
      }
    }
    allRidesPromotedSubBlock(filter: { park: { eq: "FerrariShows" }, lang: { eq: $locale } }) {
      edges {
        node {
          description
          localImage {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          image_alt
        }
      }
    }
    allDistAtraccionesBreadCrumbBlock(
      filter: { park: { eq: "FerrariShows" }, lang: { eq: $locale } }
    ) {
      edges {
        node {
          tag
          destination_url
          name
        }
      }
    }
    allDistAtraccionesRichData(filter: { extra: { eq: "FerrariShows" }, lang: { eq: $locale } }) {
      edges {
        node {
          rich_markup {
            og_title
            rich_markup_type
            og_description
          }
        }
      }
    }
  }
`;

class Shows extends React.Component {
  state = {};

  render() {
    const heimdallData = {
      name: this.props.data.allRidesTitle.edges[0].node.name,
      subtitle: this.props.data.allRidesTitle.edges[0].node.description.replace(
        /<\/?[^>]+(>|$)/g,
        ''
      ),
      image: {
        alt: this.props.data.allRidesTitleBlock.edges[0].node.alt,
        url: this.props.data.allRidesTitleBlock.edges[0].node.localImage.childImageSharp.fluid,
      },
    };

    const buttons = {
      cta: tt('/entradas/ferrari-land', this.props.pageContext.locale),
      size: 'alone',
      color: 'orange',
      ctaText: tt('COMPRAR ENTRADAS', this.props.pageContext.locale),
    };

    const freyaDataRides = {
      cards: this.props.data.allRidesSubBlock.edges.map(ride => ({
        image: ride.node.localImage.childImageSharp.fluid,
        altImage: ride.node.image_alt,
        imgsize: 'summary',
        title: ride.node.title,
        description: ride.node.description,
        disabled: ride.node.disabled,
        summary: ride.node.summary,
        seeMore: ride.node.seeMore,
        located: ride.node.located,
        features: ride.node.features,
        possibleFilters: [ride.node.possibleFilters1, ride.node.possibleFilters2],
        buttons: {
          cta: ride.node.buttons.cta,
          size: ride.node.buttons.size,
          color: ride.node.buttons.color,
          ctaText: ride.node.buttons.ctaText,
        },
      })),
    };

    const freyaData = {
      title: tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale),
      cards: this.props.data.allRidesPromotedSubBlock.edges.map(shop => ({
        image: shop.node.localImage.childImageSharp.fluid,
        altImage: shop.node.image_alt,
        disabled: shop.node.disabled,
        title: shop.node.description,
        imgsize: 'promoted',
        buttons: {
          cta: tt('/entradas', this.props.pageContext.locale),
          size: 'alone',
          color: 'white',
          ctaText: tt('VER PROMO', this.props.pageContext.locale),
        },
      })),
    };

    return (
      <Layout layoutFromOld  locale={this.props.pageContext.locale} pageName={this.props.pageContext.pageName}>
        <SEO
          title={this.props.data.allRidesSeoData.edges[0].node._0.title}
          description={this.props.data.allRidesSeoData.edges[0].node._1.description}
          pathname={this.props.pageContext.url}
          lang={this.props.pageContext.locale}
        />
        <Hod
          data={this.props.data.allDistAtraccionesRichData.edges[0].node.rich_markup}
          url={this.props.pageContext.url}
          img={this.props.data.allRidesTitleBlock.edges[0].node.image}
        />
        <div className="is-fullWidth">
          <Heimdall data={heimdallData} />
          <div className="general-index">
            <div className="shows-content">
              <Buttons
                link={buttons.cta}
                size={buttons.size}
                color={buttons.color}
                text={buttons.ctaText}
              />
              <BlueArrow />
              <NeoAesir
                locale={this.props.pageContext.locale}
                pageName={this.props.pageContext.pageName}
                title={this.props.data.allDistAtraccionesBreadCrumbBlock.edges[0].node.name}
              />
              <AnounceCovid
                text={this.props.data.allRidesTitle.edges[0].node.description.replace(
                  /<\/?[^>]+(>|$)/g,
                  ''
                )}
                icon="espectaculo"
              />
              <Freya data={freyaDataRides} />
              {this.props.data.allShowsVideo.edges[0].node.video ? (
                <div className="video-container">
                  <Bragi url={this.props.data.allShowsVideo.edges[0].node.video} />
                </div>
              ) : null}
              <div className="rides-content-promo">
                <H4 title={tt('PROMOCIONES RELACIONADAS', this.props.pageContext.locale)} />
                <Freya data={freyaData} />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint-disable react/require-default-props */
Shows.propTypes = {
  RidesData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.object.isRequired,
  }),
};
/* eslint-enable react/require-default-props */

export default Shows;
