import React from 'react';
import './AnounceCovid.scss';
import Icon from '../../helpers/icon';
import mimirIcons from '../Mimir/mimir-icons';

const AnounceCovid = ({ icon, text, h2 }) => {
  return (
    <div className="texto-aviso">
      {icon ? (
        <Icon icon={icon} width="50" height="40" class="download-icon" iconSet={mimirIcons} />
      ) : null}
      {h2 ? <h2 className="texto">{text}</h2> : <p className="texto">{text}</p>}
    </div>
  );
};

export default AnounceCovid;
